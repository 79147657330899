<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard v-if="globaSettingsLoaded">
          <CCardHeader>
            <h3>Payment Settings</h3>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="settingsAlertType" v-if="settingsAlertMessage"> {{ settingsAlertMessage }}</CAlert>
            <h5>Multi-category bookings</h5>
            <CCol>
              <CInputCheckbox label="Override default payment methods" :checked.sync="paymentGlobalSettings.override">
              </CInputCheckbox>
            </CCol>
            <CCol sm="12" md="8" lg="7" xl="4">
              <label>Categories included</label>
              <treeselect v-model="paymentGlobalSettings.categories" :defaultExpandLevel="Infinity"
                :isDefaultExpanded="true" :multiple="true" :flat="true" :options="categories" />
            </CCol>
            <CCol v-if="paymentMethods.length > 0" sm="12" md="8" lg="7" xl="4">
              <label>Payment methods</label>
              <CMultiSelect :multiple="true" :selection="true" v-model="paymentGlobalSettings.paymentMethods"
                optionsEmptyPlaceholder="No payment methods available" selectionType="text" @update="appendValue($event)"
                :options="paymentMethods" :selected="paymentGlobalSettings.paymentMethods" />
            </CCol>
            <CRow>
              <CCol col="9"></CCol>
              <CCol class="d-flex justify-content-end">
                <CButton color="primary" @click="saveGlobalSettings()">
                  <CIcon name="cil-save" /> Save
                </CButton>
              </CCol>
            </CRow>


          </CCardBody>
        </CCard>
      </transition>
      <transition name="slide">
        <CCard>
          <CCardBody>
            <CButton color="primary" @click="create()">
              <CIcon name="cil-plus" /> Create New Payment method
            </CButton>
            <br />
            <br />
            <br />
            <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
            <CDataTable hover :items="items" :fields="fields" :items-per-page="50" pagination>
              <template #status="{ item }">
                <td>
                  <CBadge :color="getBadge(item.active)">
                    {{ item.active == true ? "Active" : "Inactive" }}
                  </CBadge>
                </td>
              </template>
              <template #name="{ item }">
                <td>
                  <strong>{{ item.nameTranslations?.at(0)?.name }}</strong>
                </td>
              </template>
              <template #type="{ item }">
                <td>
                  <strong>{{ item.paymentMethodsType == 1 ? "Stripe Card" : item.paymentMethodsType == 2 ? "Stripe Klarna" :
                    item.paymentMethodsType === 3 ? "Pay Later"
                      : item.paymentMethodsType === 4 ? "Stripe Ideal" : item.paymentMethodsType === 5 ? "Stripe Link" :
                        "Pay Later" }}</strong>
                </td>
              </template>
              <template #Edit="{ item }">
                <td>
                  <CButton color="primary" @click="edit(item.id)">
                    <CIcon name="cil-pencil" /> Edit
                  </CButton>
                </td>
              </template>
              <template #Delete="{ item }">
                <td>
                  <CButton color="danger" @click="deleteMethod(item.id)">Delete</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>
  
<script>
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "PartnerLanguages",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      fields: ["status", "type", "name", "Edit", "Delete"],
      //Alert
      alertType: "danger",
      message: null,
      settingsAlertMessage: null,
      settingsAlertType: "danger",
      categories: [],
      paymentGlobalSettings: {},
      paymentMethods: [],
      globaSettingsLoaded: false
    };
  },
  components: {
    Treeselect,
  },
  computed: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    appendValue(event) {
      this.paymentGlobalSettings.paymentMethods = event;
    },
      getRowCount(items) {
        return items.length;
      },
      deleteMethod(id) {
        let self = this;
        axios
          .delete(
            `${this.$apiAdress}/v1/PartnerPaymentMethods/${id}`
          )
          .then(function () {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully deleted payment method.";
            self.getList();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      create() {
        this.$router.push({ path: `paymentmethods/create/${this.emptyGuid}` });
      },
      edit(id){
        this.$router.push({path: `paymentmethods/create/${id}`})
      },

      getList() {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/PartnerPaymentMethods`)
          .then(function (response) {
            self.items = response.data;
            self.paymentMethods.push(...response.data.map(paymentMethod => {
                return {
                    value: paymentMethod.id,
              text: paymentMethod.paymentMethodsType == 1
                ? "Stripe Card"
                : paymentMethod.paymentMethodsType == 2
                  ? "Stripe Klarna"
                  : paymentMethod.paymentMethodsType == 5
                  ? "Stripe Link"
                  : "Pay Later"
                };
            }));
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getCategories() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/category/CategoryHierarchy")
        .then(function (response) {
          self.categories = [{
            id: "",
            label: "Main Category",
            children: self.loadCategoryHierarchy(response.data, null),
          }];
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    loadCategoryHierarchy(categories, pId) {
      return categories
        .filter((c) => c.parentId === pId)
        .map((c) => {
          var subCategories = this.loadCategoryHierarchy(c.subCategories, c.id);
          return {
            id: c.id,
            label: c.names[0].name,
            children: subCategories.length > 0 ? subCategories : undefined,
          };
        });
    },
    saveGlobalSettings() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/PaymentGlobalSettings`, self.paymentGlobalSettings)
        .then((response) => {
          self.settingsAlertType = "success";
          self.settingsAlertMessage = "Successfully updated settings.";
          setTimeout(function () {
              self.settingsAlertMessage = null;
          }, 5000);
        })
        .catch(function (error) {
          self.settingsAlertType = "danger";
          self.settingsAlertMessage = error.response.data.message;
          setTimeout(function () {
              self.settingsAlertMessage = null;
          }, 10000);
        });
    },
    getGlobalSettings() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PaymentGlobalSettings`)
        .then((response) => {
          if (response.data)
            self.paymentGlobalSettings = response.data;
          self.globaSettingsLoaded = true;
        })
        .catch(function (error) {
          self.settingsAlertType = "danger";
          self.settingsAlertMessage = error.response.data.message;
          self.globaSettingsLoaded = true;
          setTimeout(function () {
              self.settingsAlertMessage = null;
          }, 10000);
        });
    },

  },
  mounted: function () {
    this.getList();
    this.getCategories();
    this.getGlobalSettings();
  },
};
</script>
  
<style scoped>
.card-body>>>table>tbody>tr>td {
  cursor: pointer;
}
</style>
  